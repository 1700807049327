import Checkbox from '@mui/material/Checkbox'
import { Version } from 'reducers/types'

import './style.scss'
import SelectBox, { BoxStyle } from '../selectBox/SelectBox'

type Props = {
  list: Version[];
  objectId: string;
  title: string;
  onChange: (string) => void;
}

export default function Column({
  list, objectId, onChange, title,
}: Props) {
  return (
    <div className="d-flex flex-column h-100">
      <div style={{ fontSize: 16, fontWeight: 800, marginBottom: 24 }}>{title}</div>
      <div className="d-flex flex-column" style={{ overflowY: 'auto', overflowX: 'hidden', paddingRight: 5 }}>
        {list.map(object => (
          <Checkbox
            key={object.id}
            sx={{ paddingX: 0 }}
            checked={objectId === object.id}
            onChange={() => onChange(object.id)}
            disableRipple
            icon={<SelectBox text={object.name} style={BoxStyle.unselected} />}
            checkedIcon={(
              <SelectBox
                text={object.name}
                style={BoxStyle.selected}
              />
            )}
          />
        ))}
      </div>

    </div>
  )
}
