import { signal } from '@preact/signals-react'
import { ReactComponent as ChevronIcon } from 'assets/icons/rightChevron.svg'
import {
  ReactNode, useEffect, useRef, useState,
} from 'react'

import { PANEL_OPTIONS } from 'utils/panel'
import './PanelsManager.scss'
import { useAppSelector } from 'hooks'
import { RootState } from 'reducers/store'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'

/**
 * Signal used to communicate with right panel
 * which is responsible for displaying/hiding the content
*/
export const PanelSignal = signal<React.ReactNode>(undefined)

// todo add transition on open/close
export default function PanelsManager() {
  const managerRef = useRef<HTMLDivElement>(null)
  const [displayed, setDisplayed] = useState(false)
  const [activePanel, setActivePanel] = useState(undefined)
  const { trainWebsocket } = useAppSelector((state: RootState) => state.snci)

  const handleClose = () => {
    PanelSignal.value = undefined
    setActivePanel(undefined)
    setDisplayed(false)
    trainWebsocket.current.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.empty,
    }))
  }

  useEffect(() => {
    if (!PanelSignal.value) return
    setDisplayed(true)
  }, [PanelSignal.value])

  useEffect(() => {
    if (!managerRef.current) return undefined

    const handleTransitionEnd = () => {
      if (displayed) return
      PanelSignal.value = undefined
    }

    managerRef.current.addEventListener('transitionend', handleTransitionEnd)
    return () => managerRef?.current?.removeEventListener('transitionend', handleTransitionEnd)
  }, [managerRef.current, displayed])

  const openPanel = (component: ReactNode, index: number) => () => {
    if (PanelSignal.value === component || index === activePanel) {
      handleClose()
      return
    }
    setActivePanel(index)
    PanelSignal.value = component
  }

  return (
    <div className="toolbar-panel">
      <div className="sidebar">
        {PANEL_OPTIONS.map((option, index) => (
          <button
            className={`${activePanel === index ? 'selected' : ''}`}
            key={option.name}
            type="button"
            onClick={openPanel(option.component, index)}
          >
            {option.icon}
          </button>
        ))}

        {!!PanelSignal.value && (
        <button type="button" onClick={handleClose}>
          <ChevronIcon />
        </button>
        )}

      </div>
      <div ref={managerRef} className={`panels-manager hide-scroll${displayed ? ' displayed' : ' hidden'}`}>
        {PanelSignal.value}
      </div>
    </div>
  )
}
