export type Train = {
  id: string;
  index: string;
}

export enum SightState {
  onSight = 'ON_SIGHT',
  notOnSight = 'NOT_ON_SIGHT',
  transitionToSight = 'TRANSITION_TO_ON_SIGHT'
}

export enum Direction {
  ascending = 'ASCENDING',
  descending = 'DESCENDING'
}

export type TrainDetails = {
  index: string;
  onSightState: SightState;
  pk: string;
  speed: number;
  direction: Direction;
}
