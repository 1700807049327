import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import terms from 'assets/localize/terms'
import { modalSignal } from 'components/modals/wrapper'
import './style.scss'
import { isTcoWindow } from 'services/route'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'

export default function ConfirmPopup() {
  const text = isTcoWindow() ? terms.Tco.closeConfirmation : terms.Snci.closeConfirmation
  return (
    <div className="confirm-popup">
      <div className="warning">
        <WarningAmberIcon />
      </div>
      <div className="content-wrapper">

        <div className="header">{text}</div>

        <div className="body">{terms.Tco.runningSimulation}</div>
      </div>

      <div className="action-buttons">
        <SimpleButton
          style={ButtonStyle.light}
          onClick={() => { modalSignal.value = undefined }}
          text={terms.Common.cancel}
        />

        <SimpleButton
          onClick={() => {
            modalSignal.value = undefined
            window.close()
          }}
          text={terms.Common.continue}
        />

      </div>
    </div>
  )
}
