import { useRef, useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import IconButton from '@mui/material/IconButton'
import editPen from 'assets/icons/edit-pen.svg'
import SaveIcon from '@mui/icons-material/Save'
import { patchSimulationName } from 'reducers/simulations/thunks'
import './style.scss'
import { BroadcastMessage } from 'types/interfaces'
import { Simulation } from 'reducers/types'
import { PayloadAction } from '@reduxjs/toolkit'

function VersionInput() {
  const dispatch = useAppDispatch()
  const { selectedSimulation, broadcast } = useAppSelector(state => state.simulations)
  const [disableInput, setDisableInout] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')

  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClickPen = () => {
    setDisableInout(!disableInput)
  }

  const handleSave = () => {
    setDisableInout(!disableInput)
    dispatch(patchSimulationName(selectedSimulation.id, inputValue)).then(res => {
      if (res.type.includes('/fulfilled')) {
        if (broadcast?.current) {
        // todo refacto
          broadcast.current.postMessage({
            type: BroadcastMessage.updateName,
            // todo fix dupe payload
            message:
          ((res.payload as PayloadAction<Simulation>).payload as Simulation).name,
          })
        }
      }
    })
  }

  useEffect(() => {
    if (inputRef.current) {
      const newInputSize = inputRef.current.scrollWidth + 2
      inputRef.current.style.width = `${newInputSize}px`
    }
  }, [inputValue])

  useEffect(() => {
    if (selectedSimulation) {
      setInputValue(selectedSimulation.name)
    }
  }, [selectedSimulation])

  return (
    <div className="version-input">
      <input
        type="text"
        ref={inputRef}
        value={inputValue}
        placeholder="Version"
        disabled={disableInput}
        onChange={e => setInputValue(e.target.value)}
      />
      {disableInput ? (
        <IconButton aria-label="delete" onClick={() => handleClickPen()}>
          <img src={editPen} alt="" />
        </IconButton>
      ) : (
        <IconButton aria-label="delete" onClick={() => handleSave()}>
          <SaveIcon sx={{ color: 'black' }} />
        </IconButton>
      )}

    </div>
  )
}

export default VersionInput
