import { useEffect, useRef } from 'react'
import { LongBeep, ShortBeep } from 'reducers/types'
import { isLongBeep } from 'services/snci'

type Props = {
  beep: ShortBeep | LongBeep;
  canPlay: boolean;
  mute: boolean;
}

export default function AudioPlayer({ beep, canPlay, mute }: Props) {
  const audioContextRef = useRef(null)
  const oscillatorRef = useRef(null)
  const gainRef = useRef(null)

  const handleMute = () => {
    if (gainRef.current) {
      gainRef.current.gain.value = mute ? 0 : 1
    }
  }
  const playSound = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new window.AudioContext()
      gainRef.current = audioContextRef.current.createGain()
      gainRef.current.connect(audioContextRef.current.destination)
      handleMute()
    }

    const audioContext = audioContextRef.current
    const oscillator = audioContext.createOscillator()
    oscillator.connect(gainRef.current)

    if (isLongBeep(beep)) {
      const { waveType, frequency, state } = beep

      if (!state) {
        if (oscillatorRef.current) {
          oscillatorRef.current.stop()
          oscillatorRef.current = null
        }
        return
      }

      oscillatorRef.current = oscillator
      oscillatorRef.current.type = waveType.toLowerCase()
      oscillatorRef.current.frequency.setValueAtTime(frequency, audioContext.currentTime)
      oscillatorRef.current.start()
    } else {
      const { waveType, frequency, duration } = beep
      oscillator.type = waveType.toLowerCase()
      oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime)
      oscillator.start()
      oscillator.stop(audioContext.currentTime + duration)
    }
  }

  useEffect(() => {
    handleMute()
  }, [mute])

  useEffect(() => {
    if (beep && canPlay) {
      playSound()
    }
  }, [beep, canPlay])

  return (null)
}
