import terms from 'assets/localize/terms'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import './TrainList.scss'
import { useEffect } from 'react'
import { useAppSelector } from 'hooks'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import TrainDetails from 'components/trainDetails/TrainDetails'
import { PanelSignal } from './PanelsManager'

export default function TrainList() {
  const { trainWebsocket, trains } = useAppSelector(state => state.snci)

  useEffect(() => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainList,
    }))
  }, [])

  const getTrainDetail = (id: string) => {
    PanelSignal.value = <TrainDetails id={id} />
  }

  return (
    <>
      <h3 className="panel-title">{terms.Snci.Panel.trainList}</h3>

      <div className="separator" />
      <div className="train-panel">
        {trains.length
          ? trains.map(train => (
            <button key={train.id} type="button" onClick={() => getTrainDetail(train.id)}>
              <div className="info">
                {`${terms.Snci.Panel.train} ${train.index}`}

                <PlayCircleIcon width={18} height={18} />
              </div>

            </button>
          )) : terms.Snci.Panel.noTrains}
      </div>
    </>
  )
}
