import terms from 'assets/localize/terms'
import { useAppSelector } from 'hooks'
import { ReactElement, useEffect } from 'react'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import { ReactComponent as ChevronIcon } from 'assets/icons/rightChevron.svg'
import './style.scss'
import { useDispatch } from 'react-redux'
import { resetTrainDetails } from 'reducers/snci/slices'
import { PanelSignal } from 'pages/snci/views/PanelsManager'
import TrainList from 'pages/snci/views/TrainList'
import { SightState } from 'types/snci'
import { isDevEnv } from 'utils'

type Props = {
  id: string;
}
export default function TrainDetails({ id }: Props): ReactElement {
  const dispatch = useDispatch()
  const { trainWebsocket, trainDetails } = useAppSelector(state => state.snci)

  useEffect(() => () => {
    dispatch(resetTrainDetails())
  }, [])

  const changePanel = () => {
    PanelSignal.value = <TrainList />
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainList,
    }))
  }

  useEffect(() => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainDescription,
      trainId: id,
    }))
  }, [])

  const changeSightMode = (state: SightState) => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainOnSight,
      onSight: state === SightState.onSight,
      trainId: id,
    }))
  }

  return (
    <div className="train-details">

      <div className="title">
        <button type="button" onClick={changePanel}><ChevronIcon width={30} height={30} /></button>

        <h3 className="index">{`${terms.Snci.Panel.train} ${trainDetails.index}`}</h3>
      </div>

      <div className="info-wrapper">
        <div className="field-container">
          <div className="label">Type</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Composition</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Traction</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Longueur</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Destination</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Sens de circulation</div>
          <div className="value">{terms.Snci.Panel[trainDetails.direction.toLowerCase()]}</div>
        </div>

        <div className="field-container">
          <div className="label">Vitesse réelle (km/h)</div>
          <div className="value">{trainDetails.speed}</div>
        </div>
        <div className="field-container">
          <div className="label">PK (tête de train)</div>
          <div className="value">{trainDetails.pk.replace('_', '+')}</div>
        </div>

        <div className="field-container">
          <div className="label">Observation</div>
          <div className="value">-</div>
        </div>

        <div className="field-container">
          <div className="label">Détail de la composition</div>
          <div className="value">-</div>
        </div>

      </div>

      {isDevEnv() && (
      <div className="circulation">
        <span>{terms.Snci.Panel.circulationMode}</span>
        <div className="toggle-switch">
          <button
            type="button"
            disabled={trainDetails.onSightState === SightState.transitionToSight}
            onClick={() => changeSightMode(SightState.notOnSight)}
            className={`option${trainDetails.onSightState === SightState.notOnSight ? ' active' : ''}`}
          >
            {terms.Snci.Panel.notOnSight}
          </button>
          <button
            type="button"
            disabled={trainDetails.onSightState === SightState.transitionToSight}
            onClick={() => changeSightMode(SightState.onSight)}
            className={`option${trainDetails.onSightState === SightState.onSight ? ' active' : ''}`}
          >
            {terms.Snci.Panel.onSight}
          </button>
        </div>
      </div>
      )}

    </div>
  )
}
