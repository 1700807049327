import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { IconButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import NotStartedIcon from '@mui/icons-material/NotStarted'
import {
  ReactElement, useEffect, useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { resetSimulation } from 'reducers/simulations/slices'
import { RootState } from 'reducers/store'
import { TrainerState } from 'reducers/trainer/slices'
import { getDashboard } from 'reducers/trainer/thunks'
import { SocketMessage } from 'types/websocket'
import './style.scss'
import { UserInfoState } from 'reducers/userInfo/slices'
import { getUsersInfo } from 'reducers/userInfo/thunks'
import { Simulation } from 'reducers/types'
import SimpleModal from 'components/modals/simpleModal/SimpleModal'
import { SimulationStream } from 'components'

export default function Trainer(): ReactElement {
  const dispatch = useAppDispatch()
  const { dashboard, students } = useAppSelector((state:RootState) => state.trainer as TrainerState)
  const { usersInfo } = useAppSelector((state:RootState) => state.userinfo as UserInfoState)
  const urlParams = useParams()
  const { webSocket } = useAppSelector(state => state.simulations)
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!dashboard) {
      dispatch(getDashboard(urlParams.id))
    }
  }, [])

  useEffect(() => {
    if (students.length) {
      if (students.some(st => !dashboard?.simulations?.some(simu => st.simulationId === simu.id))) {
        dispatch(getDashboard(urlParams.id))
      }
    }
  }, [students])

  const pauseSimulations = () => {
    webSocket?.current?.send(JSON.stringify({
      pause: !dashboard.forcePause,
      messageType: 'FORCE_PAUSE',
    }))
  }

  const sendMessage = (type: string, pause?) => {
    webSocket.current.send(JSON.stringify({
      messageType: type,
      pause,
    }))
  }

  const handleConfirmModal = () => {
    navigate('/')
    if (webSocket?.current) {
      sendMessage(SocketMessage.stop)
    }
    dispatch(resetSimulation())
  }

  const getPauseIcon = () => {
    if (typeof dashboard?.forcePause !== 'boolean') {
      return <NotStartedIcon fontSize="inherit" />
    }
    return !dashboard?.forcePause ? <PauseCircleIcon fontSize="inherit" /> : <PlayCircleIcon fontSize="inherit" />
  }

  const formatUsername = (sim: Simulation) => {
    const id = sim.userId
    const random = sim.name
    return `${usersInfo.find(u => u.id === id)?.displayName || ''} - ${random}`
  }

  useEffect(() => {
    if (!dashboard?.simulations?.length) return
    if (dashboard.simulations.some(sim => (!usersInfo.find(user => user.id === sim.userId)))) {
      const users = dashboard.simulations.map(user => user.userId)
      dispatch(getUsersInfo(users))
    }
  }, [dashboard])

  return (
    <>
      <div>
        <div style={{ fontSize: 24, paddingLeft: 10, paddingTop: 10 }}>{dashboard?.code}</div>
      </div>
      <SimpleModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={handleConfirmModal}
        title="Souhaitez-vous vraiment quitter la simulation?"
        confirmBtn="Quitter"
      />
      <IconButton
        aria-label="pause"
        sx={{ fontSize: '2.75rem' }}
        onClick={pauseSimulations}
        color="primary"
        disabled={typeof dashboard?.forcePause !== 'boolean'}
      >
        {getPauseIcon()}
      </IconButton>

      <IconButton aria-label="stop" sx={{ fontSize: '2.75rem' }} onClick={() => setOpenModal(true)}>
        <StopCircleIcon fontSize="inherit" color="error" />
      </IconButton>
      <div className="clocks">
        {(dashboard?.simulations || []).map(sim => {
          const student = students.find(st => st.simulationId === sim.id)
          return (
            <div key={sim.id}>
              <p>{formatUsername(sim)}</p>
              {sim?.viewerStatus === 'STOPPED'
                ? <strong style={{ marginLeft: '24px' }}>Simulation arrêtée</strong> : (
                  <div className="clock-time">
                    <AccessTimeIcon className="icon" color="primary" />
                    <b>{student?.timestamp ? moment(student?.timestamp).format('HH:mm:ss') : '--:--:--'}</b>
                  </div>
                )}

              <div className="state">
                Démarrée :
                {' '}
                {student?.start ? 'Oui' : 'Non'}
                {'\n'}
                En Pause :
                {' '}
                {student?.pause ? 'Oui' : 'Non'}
              </div>
            </div>
          )
        })}
      </div>

      <SimulationStream type="dashboard" />
    </>
  )
}
